<template>
  <c-loader-status :loaders="{ 'no-initial-content': [viewModel.$load] }">
    <v-sheet :class="{ 'is-sm-and-down': display.smAndDown }">
      <FullCalendar
        ref="fullCalendar"
        :options="calendarOptions"
        :key="parameters.tab + parameters.calendarType"
      >
        <template v-slot:eventContent="arg">
          <div
            class="events-calendar-details"
            v-bind="props"
            :style="`background-color: ${arg.event.backgroundColor || ''}; border-color: ${arg.event.backgroundColor || ''}`"
          >
            <b>{{
              arg.event.start.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</b>
            {{ arg.event.title }}
          </div>
        </template>
      </FullCalendar>
      <v-menu
        v-if="selectedElement"
        content-class="event-calendar-details elevation-0 pa-4"
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        :offset="[0, 10]"
        transition="slide-x-transition"
      >
        <v-card
          content-class="details-card elevation-8"
          border
          v-if="selectedEvent?.dto"
        >
          <div
            :style="`
              height: 100%;
              width: 5px;
              border-radius: 5px 0 0 5px;
              position: absolute;
              background: ${selectedElement.computedStyleMap().get('background-color')};
              margin-left: -1px;
            `"
          ></div>
          <v-card-title
            style="font-size: 30px"
            class="d-flex flex-nowrap align-start"
          >
            <div style="word-break: normal">
              <i class="fal fa-calendar-day text-primary pr-3"></i>
              {{ selectedEvent.dto!.eventName }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              variant="flat"
              icon="fal fa-times"
              @click="selectedOpen = false"
              title="Close"
            />
          </v-card-title>
          <v-card-text class="text-grey-darken-3" style="max-width: 800px">
            <event-date-summary :eventDate="selectedEvent.dto" />
            <template v-if="hasDescriptors">
              <v-divider class="my-2"></v-divider>
              Status:
              <slot
                name="list-item-descriptors"
                v-bind:eventDate="selectedEvent.dto"
              />
            </template>
          </v-card-text>
          <v-card-actions class="px-4 pb-4 pt-0">
            <slot name="actions" v-bind:eventDate="selectedEvent.dto" />
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </c-loader-status>
</template>

<style lang="scss">
.details-card {
  max-width: 650px;
  min-width: 550px;
}

.is-sm-and-down .event-calendar-details {
  left: 0 !important;
  right: 0 !important;
  max-width: 100%;
  width: 100%;

  .details-card {
    min-width: 350px;
    max-width: 100%;
  }
}

.events-calendar-details {
  color: white;
  max-height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  width: 100%;
}

.fc-v-event,
.fc-h-event {
  border: none !important;
}

.fc-daygrid-event {
  white-space: normal;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400 !important;
}

.fc-daygrid-day-top {
  max-height: 24px;
}

.fc-popover {
  outline: solid rgb(var(--v-theme-primary)) 3px;

  .fc-popover-body {
    max-height: 170px;
    overflow-y: scroll;
  }
}

.fc-daygrid-day-frame {
  min-height: 100px;
  max-height: 210px !important;
  margin-bottom: 4px !important;
}

.fc .fc-button .fc-icon {
  vertical-align: top;
}

.v-btn .v-icon {
  margin: 4px 4px;
}

.fc-scroller {
  overflow-y: auto !important;
}

.fc-daygrid-day-events {
  min-height: 80px !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin: 0;
}

.fc-toolbar-chunk {
  text-align: end;

  .fc-toolbar-title {
    text-align: start;
    @media only screen and (max-width: 660px) {
      font-size: 24px;
      font-weight: 500;
    }
  }
}
</style>

<script setup lang="ts">
import { format } from "date-fns";
import { EventDateDto } from "@/models.g";
import { EventDateDtoListViewModel } from "@/viewmodels.g";
import { EventDatesParameters } from "@common/utils";

import EventDateSummary from "@common/event/event-summary-section-content.vue";
import { useDisplay } from "vuetify";

import FullCalendar from "@fullcalendar/vue3";
import { DatesSetArg, EventClickArg, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

interface CalendarEvent {
  name: string | null;
  description: string | null;
  start: string;
  end: string;
  color: string;
  dto: EventDateDto;
}

type CalendarSource = EventDateDto.DataSources.CalendarSource;

const dayFormat = "yyyy-MM-dd";
const dateFormat = dayFormat + " HH:mm";

const props = withDefaults(
  defineProps<{
    /** `EventDateDtoListViewModel` with a CalendarSource. */
    viewModel: EventDateDtoListViewModel;
    hasDescriptors?: boolean;
    parameters: EventDatesParameters;
  }>(),
  { hasDescriptors: false },
);

const selectedEvent = ref<CalendarEvent | null>(null);
const selectedElement = ref<Element | null>(null);
const selectedOpen = ref(false);

const dataSource = computed(() => {
  return props.viewModel.$params.dataSource as CalendarSource;
});

const events = computed<EventInput[]>(() => {
  return props.viewModel.$items.map((eventDateDto: EventDateDto) => ({
    title: (eventDateDto.soldOut ? "SOLD OUT - " : "") + eventDateDto.eventName,
    start: format(eventDateDto.eventStartDateTimeLocal || 0, dateFormat),
    end: format(eventDateDto.eventEndDateTimeLocal || 0, dateFormat),
    backgroundColor: generateEventColor(eventDateDto),
    extendedProps: {
      description: eventDateDto.eventLongDescription ?? "Untitled Description", // Ensure non-null description
      dto: eventDateDto,
    },
  })) as EventInput[];
});

const display = useDisplay();
const fullCalendar = ref<typeof FullCalendar>();
const calendarApi = computed(() => fullCalendar.value?.getApi());

props.viewModel.$useAutoLoad({ wait: 10 });
props.viewModel.$load().then(async () => {
  await nextTick();

  if (fullCalendar.value) {
    calendarApi.value?.on("datesSet", handleDatesSet);
  }
});

const calendarOptions = computed(() => {
  return {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialDate: new Date(props.parameters.calendarDate) || new Date(),
    initialView:
      props.parameters.calendarType === "day" ? "timeGridDay" : "dayGridMonth",
    events: events.value,
    eventClick: showEvent,
    dateClick: viewDay,
    height: "auto",
    firstDay: 0,
    dayMaxEvents: 7,
    fixedWeekCount: false,
    titleFormat: {
      month: props.parameters.calendarType == "day" ? "short" : "long",
      year: "numeric",
      day: props.parameters.calendarType == "day" ? "numeric" : undefined,
    },
  };
});

watch(
  () => props.parameters.calendarType,
  (type) => {
    if (type === "day") {
      try {
        calendarApi.value?.changeView("timeGridDay");
        calendarApi.value?.render();
      } catch (e) {
        console.error("Error changing to time view");
      }
    } else {
      try {
        calendarApi.value?.changeView("dayGridMonth");
        calendarApi.value?.render();
      } catch (e) {
        console.error("Error changing to time view");
      }
    }
  },
);

async function handleDatesSet(info: DatesSetArg) {
  if (props.parameters.calendarType === "day") return;
  const startDate = new Date(info.startStr);

  rangeChange(startDate);
}

function rangeChange(startDate: Date) {
  const previousMonthCalendarOffset = startDate.getDate() != 1 ? 1 : 0;
  const startMonth = startDate.getMonth() + 1 + previousMonthCalendarOffset; // JavaScript months are zero-indexed, so add 1

  dataSource.value.year =
    startMonth != 13 ? startDate.getFullYear() : startDate.getFullYear() + 1;
  dataSource.value.month = startMonth != 13 ? startMonth : 1;
}

async function viewDay(arg: { date: Date }) {
  const selectedDate = arg.date; // Get the clicked date

  // Change the calendar type first
  props.parameters.calendarType = "day";

  // Wait for the DOM/UI to update
  await new Promise((resolve) => setTimeout(resolve, 0));

  if (calendarApi.value) {
    // Move FullCalendar to the selected date
    calendarApi.value.gotoDate(selectedDate);

    // Change view to `timeGridDay`
    calendarApi.value.changeView("timeGridDay");
  }
}


function showEvent(arg: EventClickArg) {
  const open = () => {
    // Assign the event details to `selectedEvent`.
    // Cast `extendedProps` to match `CalendarEvent` type if necessary.
    selectedEvent.value = arg.event.extendedProps as CalendarEvent;

    // Store the clicked element for activating the menu.
    selectedElement.value = arg.jsEvent.target as Element;

    // Delay opening the menu slightly for UI smoothness
    setTimeout(() => (selectedOpen.value = true), 10);
  };

  if (selectedOpen.value) {
    // If already open, close and reopen for the new event.
    selectedOpen.value = false;
    setTimeout(open, 10);
  } else {
    open();
  }

  // Stop propagation of the click event to prevent other handlers.
  arg.jsEvent.stopPropagation();
}

function hexToRgba(hex: string, alpha: number): string {
  let r = 0,
    g = 0,
    b = 0;

  // Convert hex to RGB
  if (hex.length == 4) {
    // 3-digit hex
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    // 6-digit hex
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r},${g},${b},${alpha})`;
}

function generateEventColor(eventDateDto: EventDateDto): string {
  if (eventDateDto.soldOut) {
    // Return a semi-transparent red color for sold-out events
    return "rgba(223,50,59,0.5)";
  }

  const timeOfDay = format(eventDateDto.eventStartDateTimeLocal || 0, "HH:mm");
  const hashInput = eventDateDto.eventName + timeOfDay;

  let hash = 0;
  for (let i = 0; i < hashInput.length; i++) {
    const char = hashInput.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  const colorMap: Record<string, string> = {
    "red darken-4": "#B71C1C",
    "pink darken-2": "#C2185B",
    orange: "#FF9800",
    indigo: "#3F51B5",
    green: "#4CAF50",
    blue: "#2196F3",
    "blue-grey": "#607D8B",
  };

  const colors = Object.keys(colorMap);

  const colorIndex = Math.abs(hash) % colors.length;
  const colorName = colors[colorIndex];
  const hexColor = colorMap[colorName] || "#000000"; // Default to black if not found

  // Convert the hex color to RGBA with the desired transparency (e.g., 0.5)
  // Adjust alpha as desired (0 to 1)
  return hexToRgba(hexColor, 0.9);
}

const swipe = (dir: "left" | "right") => {
  if (dir === "left") {
    calendarApi.value.next();
  } else if (dir === "right") {
    calendarApi.value.prev();
  }
};
defineExpose({ swipe });
</script>
