<template>
  <v-app id="vue-app">
    <v-app-bar
      class="px-4"
      height="96"
      v-if="staticData.APP_NAME"
      color="white"
      dense
    >
      <img
        :src="staticData.fullLogoSrc"
        @click="router.push('/')"
        class="header-logo"
        :alt="staticData.APP_NAME"
      />

      <v-spacer />

      <div
        class="text-body-2 align-self-center flex-shrink-1"
        v-if="staticData.APP_SUPPORT_EMAIL || staticData.APP_SUPPORT_PHONE"
      >
        <div class="font-weight-bold pt-1">Contact Us:</div>
        <!-- Table because of too many flexbugs in IE -->
        <table>
          <tr v-if="staticData.APP_SUPPORT_EMAIL">
            <td>
              <i class="fal fa-fw fa-at"></i>
            </td>
            <td>
              <a
                class="contact-link"
                :href="'mailto:' + staticData.APP_SUPPORT_EMAIL"
              >
                {{
                  staticData.APP_SUPPORT_EMAIL.substr(
                    0,
                    staticData.APP_SUPPORT_EMAIL.indexOf("@"),
                  )
                }}
                <wbr />
                {{
                  staticData.APP_SUPPORT_EMAIL.substr(
                    staticData.APP_SUPPORT_EMAIL.indexOf("@"),
                  )
                }}
              </a>
            </td>
          </tr>
          <tr v-if="staticData.APP_SUPPORT_PHONE">
            <td>
              <i class="fal fa-fw fa-phone"></i>
            </td>
            <td>
              <a
                class="contact-link"
                :href="'tel:' + staticData.APP_SUPPORT_PHONE"
              >
                {{ staticData.APP_SUPPORT_PHONE }}
              </a>
            </td>
          </tr>
        </table>
      </div>

      <!-- <v-btn
        class="collapsed-hide mt-2"
        :to="{ name: 'sign-in', query: { returnUrl: this.$route.fullPath } }"
        text
        color="grey lighten-1"
      >
        Log In
      </v-btn> -->
    </v-app-bar>

    <v-main class="app-content">
      <!-- https://stackoverflow.com/questions/52847979/what-is-router-view-key-route-fullpath -->
      <router-view v-slot="{ Component, route }">
        <transition name="router-transition" mode="out-in" appear>
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </v-main>

    <!-- Sign In link removed by request
    https://dev.azure.com/Hagadone/Ticketing/_boards/board/t/Ticketing%20Team/Backlog%20items/?workitem=690 -->
    <!--
    <router-link
      :to="{ name: 'sign-in', query: { returnUrl: $route.fullPath } }"
      style="position: absolute; left: 0; bottom: 0"
      class="grey--text text--lighten-1 body-2 py-1 px-2 no-print"
    >
      Sign In
    </router-link> -->
    <v-sheet max-height="100px">
      <v-footer class="d-flex justify-center">
        <div class="d-flex flex-column align-center">
          <p>
            Powered By
            <a
              style="color: #222222"
              class="text-decoration-underline"
              href="https://www.ticketgoat.com/"
              target="_blank"
              >Ticket Goat</a
            >
          </p>
        </div>
      </v-footer>
    </v-sheet>
  </v-app>
</template>

<style lang="scss">
.header-logo {
  object-fit: contain;
  max-height: 100%;
  display: block;
  padding: 4px 0;

  transition: all 0.2s ease;

  @media (min-width: 960px) {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
<script setup lang="ts">
import router from "@public/router";
import { useDisplay } from "vuetify";

const staticData = useStaticData();
const display = ref(useDisplay());
</script>

<style lang="scss">
#vue-app {
  .v-toolbar--collapsed {
    .collapsed-hide {
      visibility: hidden;
    }
  }

  .router-transition-enter-active,
  .router-transition-leave-active {
    // transition: 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.1s ease-out;
  }

  .router-transition-move {
    transition: transform 0.4s;
  }

  .router-transition-enter,
  .router-transition-leave-to {
    opacity: 0;
    // transform: translateY(5px);
  }
}
</style>
